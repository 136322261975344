  .body {
      margin: 1em 0;
      overflow: hidden;
      overflow-x: auto;

      .row {
          display: grid;
          align-items: center;
          margin-bottom: 8px;
          padding: 4px 0;
          transition: 0.2s;
          width: 100%;
          gap: 8px;
          max-width: 100%;

          &:hover {
              background-color: #cce5ff;
          }

          &:nth-child(odd) {
              background: #ecedee;
          }

          &:first-child {
              background: #626262;

              &:hover {
                  background-color: #626262;
              }
          }

          &:hover {
              background-color: #cce5ff;
          }

          .cell {
              overflow: hidden;
              max-height: 120px;
              overflow-y: auto;
          }
      }

      .header {
          width: 100%;
          max-width: 100%;
          position: sticky;
          top: 0;
          z-index: 1;
          background: #626262;
          color: #fff;
          font-weight: bold;
      }
  }

  .tableWrap {
      margin: 1em 0;

      .table {
          display: grid;
          overflow-x: scroll;

          .header {
              display: grid;
              position: sticky;
              top: 0;
              z-index: 1;
              background: #626262;
              color: #fff;
              font-weight: bold;
              background: #626262;

              >div {
                  background: #626262;
              }

              &:hover {
                  background-color: #626262;
              }

          }

          .content {
              display: grid;

              >div {
                  display: grid;
                  align-items: center;
                  transition: .2s;

                  &:nth-child(odd) {
                      background: #ecedee;
                  }

                  &:hover {
                      background-color: #cce5ff;
                  }
              }
          }
      }
  }