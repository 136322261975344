%btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, text-decoration 0.15s ease-in-out;
}

%linkLikeBtn {
  border: 1px solid;
  border-radius: 4px;
  margin-block-end: 10px;
  margin-inline: 8px;
  text-decoration: none;

}

%btnWarning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;

  &:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
}

%btnLight {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;

  &:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
}

%btnSuccess {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;

  &:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
}

%btnDanger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
}

%btnPrimary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;

  &:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
}

%btnSecondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;

  &:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
}

%btnLink {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
  text-decoration: none;

  &:hover {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent;
  }
}


.btn {
  @extend %btn;
}

.btnSuccess {
  @extend %btnSuccess;
}

.linkLikeBtn {
  @extend %linkLikeBtn;
}

.btnDanger {
  @extend %btnDanger;
}

.btnLight {
  @extend %btnLight;
}

.btnWarning {
  @extend %btnWarning;
}

.btnPrimary {
  @extend %btnPrimary;
}

.btnSecondary {
  @extend %btnSecondary;
}

.btnLink {
  @extend %btnLink;
}


%message {
  white-space: pre-line;
  font-size: 16px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
  display: block;
  font-weight: bold;
}

%message-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

%message-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

%message-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

%message-attantion {
  color: #040300;
  background-color: #f69749;
  border-color: #be641b;
}

%message-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

%message-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.message {
  @extend %message;
}

.message-dark{ 
  @extend %message-dark;
}

.message-info {
  @extend %message-info;
}

.message-success {
  @extend %message-success;
}

.message-warning {
  @extend %message-warning;
}

.message-attantion {
  @extend %message-attantion;
}

.message-danger {
  @extend %message-danger;
}

.modal {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  background: #25252599;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  justify-content: space-around;
  align-items: center;

  .modalContent,
  .simpleModalContent {
    position: relative;
    background: #fff;
    box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.5);
    padding: 20px 10px;
    border-radius: 16px;
    min-width: 320px;
    font-size: 1.2em;

    .btnClose {
      position: absolute;
      border-radius: 8px;
      padding: 1px 8px;
      font-size: 11px;
      top: 8px;
      right: 8px;
    }

    >div {
      margin-bottom: 16px;
    }

    .modalButtons {
      >div {
        margin-left: 8px;
      }
    }

    .modalAlert {
      margin-bottom: 20px;
      font-weight: 700;

      .modalAlertUser {
        font-weight: 600;
      }

      .modalAlertFlag {
        font-weight: 600;
        color: red;
      }
    }
  }

  .simpleModalContent {
    min-height: 40vh;
    max-height: 90vh;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    max-width: 65%;

    input[type="search"] {
      width: 80%;
      padding: 8px 16px;
      margin: 12px 4px 4px;
      border-radius: 8px;
      width: 100%;
      border-color: #ccc;
    }

    input[type="text"] {
      padding: 8px 16px;
      margin: 12px 4px 4px;
      border-radius: 8px;
      border-color: #ccc;
      font-size: 18px;
    }

    input[type="date"] {
      padding: 8px 16px;
      margin: 12px 4px 4px;
      border-radius: 8px;
      border-color: #ccc;
      font-size: 18px;
    }

    input[type="file"] {
      padding: 8px 16px;
      margin: 12px 4px 4px;
      border-radius: 8px;
      border-color: #ccc;
      font-size: 18px;
    }
  }
}

:root {
  --code-color: darkred;
  --code-bg-color: #aaaaaa;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}

pre {
  color: var(--code-color);
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  background-color: var(--code-bg-color);
}

.code-block {
  max-height: 100px;
  overflow: auto;
  padding: 8px 7px 5px 15px;
  margin: 0px 0px 0px 0px;
  border-radius: 7px;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.card {
  min-width: 320px;
  max-width: 1200px;
  width: 100%;
  position: relative;
  box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.5);
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  &.fluide {
    max-width: 1440px;
  }
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: textfield;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 16px Arial;
  padding: 4px;
  border: 1px solid #bbb;
}

select,
textarea {
  border: 1px solid #bbb;

}

.css-a0n6xq {
  max-height: 85vh;
  overflow: auto;
}

label {
  color: #5f5f5f;
}

.shedule-alert {
  &.shedule_list {
    max-width: max-content;
    display: inline-flex;
    align-items: center;
  }

  .MuiAlert-message {
    flex: 1 1 0;
    display: flex;
    column-gap: 10px;
    align-items: center;

    .close {
      flex: 1 1 0;
      writing-mode: vertical-rl;
      width: max-content;
      display: inline-flex;
      vertical-align: middle;
      cursor: pointer;

      svg {
        fill: red;
      }
    }
  }
}