.transfer_card {
    padding: 8px;

    .header {
        justify-content: center;
        display: flex;
        align-items: center;
        gap: 16px;

        >div {
            width: -webkit-fill-available;
        }
    }


}

.transferModal {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;

    >div {
        &:nth-child(odd) {
            background: #ecedee;
        }
    }
}

.timeWrap {
    display: flex;
    align-items: center;
    gap: 16px;
}

.timeWrapForAll {
    display: flex;
    align-items: center;
    justify-content: space-around;
}